import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="h2" components={components}>{`JS Everywhere`}</MDXTag>
      <MDXTag name="p" components={components}>{`We were using JWT for encoding sensitive data passed between the client and server, so I decided to stick with that because typing `}<MDXTag name="inlineCode" components={components} parentName="p">{`npm i`}</MDXTag>{` sounded hard.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Check out the project’s `}<a href="https://github.com/Lambda-School-Labs/LabsPT1_Backwoods" target="_blank">{`source code on GitHub`}</a>{`, or watch the 30 second demo:`}</MDXTag>
      <iframe className="youtube-video" width="720" height="480" src="https://www.youtube.com/embed/DxugdZ0kHEY" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
      <MDXTag name="p" components={components}>{`We’ll be coding this feature entirely in JavaScript. Let’s dive in!`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Dependencies`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="strong" components={components} parentName="li">{`Express & JWT:`}</MDXTag>{` I’m assuming you know enough Express to create a simple service that listens on a given route. Working knowledge of JWTs will also help; if you’re fuzzy, check out the `}<a href="https://jwt.io/" target="_blank">{`JWT website`}</a>{` for a refresher on JWT headers, payloads and signatures.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul"><MDXTag name="strong" components={components} parentName="li">{`Nodemailer:`}</MDXTag>{` Super simple `}<a href="https://nodemailer.com/about/" target="_blank">{`npm module for sending email`}</a>{`. We’ll create a quick and dirty email template and configure it to send reset emails from our own email address — make sure you use something like `}<a href="https://www.npmjs.com/package/dotenv" target="_blank">{`dotenv`}</a>{` if you plan on pushing to GitHub, otherwise your email password will be made public!`}</MDXTag>
      </MDXTag>
      <MDXTag name="h2" components={components}>{`Theory`}</MDXTag>
      <MDXTag name="p" components={components}>{`This is the part of the blog post I would usually skip personally, but `}<MDXTag name="em" components={components} parentName="p">{`I recommend you actually read this part`}</MDXTag>{`, otherwise you might end up being confused when we get to implementation.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let’s make sure we’re on the same page about a few things, security-wise:`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol">{`We’re not going to send the user her password via email.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Because we care about security, we’re using something like `}<a href="https://www.npmjs.com/package/bcryptjs" target="_blank">{`bcryptjs`}</a>{` so that `}<MDXTag name="em" components={components} parentName="li">{`we don’t store the user’s password at all`}</MDXTag>{`. At least not in plain text. Instead we hash it, and sending the user a hashed password is worse than doing nothing at all because she will probably assume her password has been compromised.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`We’re also not going to send the user a temporary password because we can’t assume the user’s inbox is secure. If the user gets distracted and forgets to follow the link we email her, there is a live password just waiting in her inbox. Not to mention a malicious actor or jilted ex could reset the user’s password over and over, keeping her from logging into the wonderful app we made.`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`Instead, we’re going to `}<MDXTag name="strong" components={components} parentName="p">{`generate a single-use link`}</MDXTag>{` that the user can follow to enter a new password. `}<MDXTag name="strong" components={components} parentName="p">{`This link will also expire after a set amount of time`}</MDXTag>{`, in this case 60 minutes.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Creating a Single-Use URL`}</MDXTag>
      <MDXTag name="p" components={components}>{`We need a way to validate our user. To do this, we’re going to `}<MDXTag name="strong" components={components} parentName="p">{`embed a JWT token that identifies her in the URL we generate`}</MDXTag>{`. When she clicks this link, our user will be presented a form that allows her to enter a new password.`}</MDXTag>
      <MDXTag name="p" components={components}>{`As a payload, the JWT token needs to carry something that we can use to effectively fingerprint the user. Once she visits the route we send her, our server will pull the token from the route params and use it to identify her.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Before we talk about the JWT payload, let’s make sure we all understand the control flow:`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Order of operations:`}</MDXTag></MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol">{`User enters her email into a password reset form which sends a POST request to the endpoint `}<MDXTag name="inlineCode" components={components} parentName="li">{`/reset_pw/user/:email`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`We sign a JWT on the backend using a dynamic payload and secret key`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`An email service mounted at that route in step 1 emails her a URL containing the token`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`The user clicks the link and is taken to a client-side form. Then, our client-side router pulls the token off params as it resolves her request (our team used React Router to do this)`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`User submits the form which makes a POST request to `}<MDXTag name="inlineCode" components={components} parentName="li">{`/new_pw/:userId/:token`}</MDXTag>{` with the new password `}<MDXTag name="strong" components={components} parentName="li">{`on the request body`}</MDXTag>{` (parameters being easier to intercept)`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`Our then server decodes the token using a secret key unique to the user, hashes the new password, and replaces the old password hash with the new one`}</MDXTag>
      </MDXTag>
      <MDXTag name="h3" components={components}>{`JWT Secret & Payload`}</MDXTag>
      <MDXTag name="p" components={components}>{`So what should our JWT’s payload be?`}</MDXTag>
      <MDXTag name="p" components={components}>{`This confused me until I realized I was asking the wrong question. A better question is: `}<MDXTag name="strong" components={components} parentName="p">{`What should our secret key be?`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`The payload itself doesn’t matter as much as the fact that it matches.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let’s say you and I are both spies, meeting for the first time. We don’t know if we can trust each other yet. Of course, no good spy actually trusts another spy, not truly; but we agree that some tests are better than others, and begin talking about ways to verify that the other is in face who she claims to be.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Before long we reach an impasse. Given our line of work, we cannot use information as a way to authenticate each other, because we might be giving something away by the very act of trying to verify it. We could try blurting out the name of the target together on three, but that’s the oldest trick in the book and neither one of us is falling for that one again.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Instead, you point out that it doesn’t actually matter `}<MDXTag name="em" components={components} parentName="p">{`what`}</MDXTag>{` we know, but that we can demonstrate the ability to know it. We don’t need to know what’s behind door number 1, so long as we both have a key that can open it.`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`To make a one-time URL, we use the user’s old hashed password as the JWT secret key. When the user updates her password, we will replace the old hash with the new one, and no one can access the secret key anymore.`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`In order to make sure this link can’t be used and reused over and over again, the link should “expire” — meaning that our token (and by extension, the URL) should only work once.`}</MDXTag>
      <MDXTag name="p" components={components}>{`After researching how to do this, I came across a very clever solution:`}</MDXTag>
      <MDXTag name="p" components={components}>{`To make a one-time URL, we use the user’s old hashed password as the JWT secret key. When the user updates her password, we will replace the old hash with the new one, and no one can access the secret key anymore.`}</MDXTag>
      <MDXTag name="p" components={components}>{`This helps to ensure that if the user’s password was the target of a previous attack (on an unrelated website), then the user’s created date will make the secret key unique from the potentially leaked password.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Get it?`}</MDXTag>
      <MDXTag name="p" components={components}>{`It took me a bit to wrap my head around.`}</MDXTag>
      <MDXTag name="p" components={components}>{`To make this even more secure, we can concatenate the old password hash with the user’s `}<MDXTag name="inlineCode" components={components} parentName="p">{`createdAt`}</MDXTag>{` value, so that if someone intercepts the user token from the network, he would still need a user’s timestamp to crack the secret key.`}</MDXTag>
      <MDXTag name="p" components={components}>{`With the combination of the user’s password hash and `}<MDXTag name="inlineCode" components={components} parentName="p">{`createdAt`}</MDXTag>{` date the JWT becomes a one-time-use token, because once the user has changed her password, `}<MDXTag name="em" components={components} parentName="p">{`successive calls to that route will generate a new password hash, invalidating the secret key which references the defunct password`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`So what should the payload be?`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`As long as it’s something unique to the user that our server can compare, that’s up to you. In our case, we used the `}<MDXTag name="inlineCode" components={components} parentName="p">{`userId`}</MDXTag>{`, and the server just made sure that the decoded payload matches our user’s ID in the database.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Implementation`}</MDXTag>
      <MDXTag name="p" components={components}>{`Now that all the theory is behind us, let’s look at one possible implementation:`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Server`}</MDXTag>
      <MDXTag name="h4" components={components}>{`File: `}<MDXTag name="inlineCode" components={components} parentName="h4">{`email.controller.js`}</MDXTag>{`:`}</MDXTag>
      <MDXTag name="p" components={components}><a href="https://github.com/Lambda-School-Labs/LabsPT1_Backwoods/blob/master/server/src/api/resources/email/email.controller.js" target="_blank">{`See it on GitHub`}</a></MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Imports:`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`import jwt from "jsonwebtoken"
import bcrypt from "bcryptjs"
import { User } from "../user/user.model"
import {
  transporter,
  getPasswordResetURL,
  resetPasswordTemplate
} from "../../modules/email"
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Here we pull in our User model and email module, which are implementation details. Again, see the `}<a href="https://github.com/Lambda-School-Labs/LabsPT1_Backwoods" target="_blank">{`GitHub repository`}</a>{` if you want to dig into all the specifics.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Make token from hash helper function:`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`// \`secret\` is passwordHash concatenated with user's
// createdAt value, so if someone malicious gets the
// token they still need a timestamp to hack it:
export const usePasswordHashToMakeToken = ({
  password: passwordHash,
  _id: userId,
  createdAt
}) => {
  // highlight-start
  const secret = passwordHash + "-" + createdAt
  const token = jwt.sign({ userId }, secret, {
    expiresIn: 3600 // 1 hour
  })
  // highlight-end
  return token
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Here we pass the old password hash, user ID and created at values into a function that signs a token with `}<MDXTag name="inlineCode" components={components} parentName="p">{`userId`}</MDXTag>{` as the payload and `}<MDXTag name="inlineCode" components={components} parentName="p">{`\${passwordHash}-\${createdAt}`}</MDXTag>{` as a secret.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Sending the email:`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`//// Sends an email IRL! ////
export const sendPasswordResetEmail = async (req, res) => {
  const { email } = req.params
  let user
  try {
    user = await User.findOne({ email }).exec()
  } catch (err) {
    res.status(404).json("No user with that email")
  }
  const token = usePasswordHashToMakeToken(user)
  const url = getPasswordResetURL(user, token)
  const emailTemplate = resetPasswordTemplate(user, url)

  const sendEmail = () => {
    transporter.sendMail(emailTemplate, (err, info) => {
      if (err) {
        res.status(500).json("Error sending email")
      }
      console.log(\`** Email sent **\`, info.response)
    })
  }
  sendEmail()
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`This function find’s our user by email, makes a token, builds up a URL, then calls our email module and fires off an email IRL.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Updating the user’s password:`}</MDXTag></MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`export const receiveNewPassword = (req, res) => {
  const { userId, token } = req.params
  const { password } = req.body

  // highlight-start
  User.findOne({ _id: userId })
    .then(user => {
      const secret = user.password + "-" + user.createdAt
      const payload = jwt.decode(token, secret)
      if (payload.userId === user.id) {
        bcrypt.genSalt(10, function(err, salt) {
          // Call error-handling middleware:
          if (err) return
          bcrypt.hash(password, salt, function(err, hash) {
            // Call error-handling middleware:
            if (err) return
            User.findOneAndUpdate({ _id: userId }, { password: hash })
              .then(() => res.status(202).json("Password changed accepted"))
              .catch(err => res.status(500).json(err))
          })
        })
      }
    })
    // highlight-end

    .catch(() => {
      res.status(404).json("Invalid user")
    })
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`This nasty function is the meat of the program. Originally we wrote this using the async/await protocol like `}<MDXTag name="inlineCode" components={components} parentName="p">{`sendPasswordResetEmail`}</MDXTag>{` above, but bcrypt didn’t seem to like async/await.`}</MDXTag>
      <MDXTag name="p" components={components}>{`What’s interesting is that, despite the callback hell, this function never created any bugs and never needed to be rewritten.`}</MDXTag>
      <MDXTag name="h4" components={components}>{`File: `}<MDXTag name="inlineCode" components={components} parentName="h4">{`email.restRouter.js`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}><a href="https://github.com/Lambda-School-Labs/LabsPT1_Backwoods/blob/master/server/src/api/resources/email/email.restRouter.js" target="_blank">{`See it on GitHub`}</a></MDXTag>
      <MDXTag name="p" components={components}>{`Then we mount the service in `}<MDXTag name="inlineCode" components={components} parentName="p">{`email.restRouter.js`}</MDXTag>{` (rest router as opposed to GraphQL router, which this blog is written in):`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-javascript"
        }}>{`import express from "express"
import * as emailController from "./email.controller"

export const emailRouter = express.Router()

emailRouter.route("/user/:email").post(emailController.sendPasswordResetEmail)

emailRouter
  .route("/receive_new_password/:userId/:token")
  .post(emailController.receiveNewPassword)
`}</MDXTag></MDXTag>
      <MDXTag name="h3" components={components}>{`What about the client?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Initially we were going to do everything on the server, but then we decided that React Router and Axios were enough to pass params to and from the Express server.`}</MDXTag>
      <MDXTag name="p" components={components}>{`There are really only 2 client-side files of interest.`}</MDXTag>
      <MDXTag name="h4" components={components}>{`File: `}<MDXTag name="inlineCode" components={components} parentName="h4">{`RecoverPassword.js`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}><a href="https://github.com/Lambda-School-Labs/LabsPT1_Backwoods/blob/master/client/src/components/forms/RecoverPassword.js" target="_blank">{`See it on GitHub`}</a></MDXTag>
      <MDXTag name="p" components={components}>{`This component doesn’t need to accept any props because the user hasn’t taken any action yet besides navigate to the component. Notice how the component renders based on local state, and how the Axios call to our server endpoint is made:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-jsx"
        }}>{`import React, { Component } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { Button, GhostInput } from "./styledComponents"
import RecoverPasswordStyles from "./RecoverPassword.styles"

import axios from "axios"
import SERVER_URI = "localhost:3000"

class RecoverPassword extends Component {
  state = {
    email: "",
    submitted: false
  }

  handleChange = e => {
    this.setState({ email: e.target.value })
  }

  sendPasswordResetEmail = e => {
    e.preventDefault()
    const { email } = this.state
    axios.post(\`\${SERVER_URI}/reset_password/user/\${email}\`)
    this.setState({ email: "", submitted: true })
  }

  render() {
    const { email, submitted } = this.state

    return (
      <RecoverPasswordStyles>
        <h3>Reset your password</h3>
        {submitted ? (
          <div className="reset-password-form-sent-wrapper">
            <p>
              If that account is in our system, we emailed you a link to reset
              your password.
            </p>
            <Link to="/login" className="ghost-btn">
              Return to sign in
            </Link>
          </div>
        ) : (
          <div className="reset-password-form-wrapper">
            <p>
              It happens to the best of us. Enter your email and we'll send you
              reset instructions.
            </p>
            <form onSubmit={this.sendPasswordResetEmail}>
              <GhostInput
                onChange={this.handleChange}
                value={email}
                placeholder="Email address"
              />
              <Button className="btn-primary password-reset-btn">
                Send password reset email
              </Button>
            </form>
            <Link to="/login">I remember my password</Link>
          </div>
        )}
      </RecoverPasswordStyles>
    )
  }
}

export default RecoverPassword
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`That’s it, it’s actually pretty simple. We can use React Router to mount it wherever we want. We went with `}<MDXTag name="inlineCode" components={components} parentName="p">{`/password/recover`}</MDXTag>{` because we had other password functionality that we bundled under the `}<MDXTag name="inlineCode" components={components} parentName="p">{`/password`}</MDXTag>{` namespace.`}</MDXTag>
      <MDXTag name="h4" components={components}>{`File: `}<MDXTag name="inlineCode" components={components} parentName="h4">{`UpdatePassword.js`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}><a href="https://github.com/Lambda-School-Labs/LabsPT1_Backwoods/blob/master/client/src/components/forms/UpdatePassword.js" target="_blank">{`See it on GitHub`}</a></MDXTag>
      <MDXTag name="p" components={components}>{`Here’s how we might use the `}<MDXTag name="inlineCode" components={components} parentName="p">{`UpdatePassword`}</MDXTag>{` component using React Router:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-jsx"
        }}>{`<Route
  path="/update-password"
  render={({ match }) => (
    <UpdatePassword userId={match.params.userId} token={match.params.token} />
  )}
/>
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`And here’s what the component itself looks like:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-jsx"
        }}>{`import React, { Component } from "react"
import axios from "axios"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

import { Button, GhostInput } from "./customStyledComponents"
import { RecoverPasswordStyles as UpdatePasswordStyles } from "./RecoverPassword"

const SERVER_URI = "localhost:3000"

class UpdatePassword extends Component {
  state = {
    password: "",
    confirmPassword: "",
    submitted: false
  }

  handleChange = key => e => {
    this.setState({ [key]: e.target.value })
  }

  updatePassword = e => {
    e.preventDefault()
    const { userId, token } = this.props
    const { password } = this.state

    axios
      .post(
        \`\${SERVER_URI}/reset_password/receive_new_password/\${userId}/\${token}\`,
        { password }
      )
      .then(res => console.log("RESPONSE FROM SERVER TO CLIENT:", res))
      .catch(err => console.log("SERVER ERROR TO CLIENT:", err))
    this.setState({ submitted: !this.state.submitted })
  }

  render() {
    const { submitted } = this.state

    return (
      <UpdatePasswordStyles>
        <h3 style={{ paddingBottom: "1.25rem" }}>Update your password</h3>
        {submitted ? (
          <div className="reset-password-form-sent-wrapper">
            <p>Your password has been saved.</p>
            <Link to="/login" className="ghost-btn">
              Sign back in
            </Link>
          </div>
        ) : (
          <div className="reset-password-form-wrapper">
            <form
              onSubmit={this.updatePassword}
              style={{ paddingBottom: "1.5rem" }}
            >
              <GhostInput
                onChange={this.handleChange("password")}
                value={this.state.password}
                placeholder="New password"
                type="password"
              />
              <GhostInput
                onChange={this.handleChange("confirmPassword")}
                value={this.state.confirmPassword}
                placeholder="Confirm password"
                type="password"
              />

              <Button className="btn-primary password-reset-btn">
                Update password
              </Button>
            </form>
          </div>
        )}
      </UpdatePasswordStyles>
    )
  }
}

UpdatePassword.propTypes = {
  token: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired
}

export default UpdatePassword
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`That’s a wrap! Of course there’s more to the implementation, but for sake of brevity I will simply link to the other relevant files here:`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol"><a href="https://github.com/Lambda-School-Labs/LabsPT1_Backwoods/blob/master/server/src/api/modules/email.js" target="_blank">{`Server-side Email module & Nodemailer config`}</a></MDXTag>
        <MDXTag name="li" components={components} parentName="ol"><a href="https://github.com/Lambda-School-Labs/LabsPT1_Backwoods/blob/master/server/src/api/restRouter.js" target="_blank">{`Server-side Rest Router API config`}</a></MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`Thanks for reading! Feel free to drop me a line if you have any questions or feedback at `}<a href="mailto:ahrjarrett@gmail.com">{`ahrjarrett@gmail.com`}</a>{`.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Check out my `}<a href="https://github.com/ahrjarrett" target="_blank">{`other projects on GitHub`}</a>{`!`}</MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {
  "path": "/2019-02-08-resetting-user-passwords-with-node-and-jwt",
  "date": "2019-02-08",
  "title": "Resetting a User’s Password Using Node.js and JWT",
  "image": "2019-02-08-resetting-user-passwords-with-node-and-jwt.jpg",
  "sharpImage": true,
  "imgOverlay": "rgba(46, 49, 146, 0.35)",
  "tags": ["nodejs", "jwt", "cybersecurity"],
  "excerpt": "Recently I was tasked with building a feature that allowed a user to reset her password via email — securely. I used Node and Express on the backend. Turns out this was both easier and harder than I expected.",
  "published": true
};
      